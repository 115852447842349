// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-partners-community-jsx": () => import("./../../../src/pages/partners/community.jsx" /* webpackChunkName: "component---src-pages-partners-community-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-partners-paid-jsx": () => import("./../../../src/pages/partners/paid.jsx" /* webpackChunkName: "component---src-pages-partners-paid-jsx" */),
  "component---src-pages-partners-sponsor-jsx": () => import("./../../../src/pages/partners/sponsor.jsx" /* webpackChunkName: "component---src-pages-partners-sponsor-jsx" */),
  "component---src-pages-partners-traffic-jsx": () => import("./../../../src/pages/partners/traffic.jsx" /* webpackChunkName: "component---src-pages-partners-traffic-jsx" */),
  "component---src-pages-platforms-jsx": () => import("./../../../src/pages/platforms.jsx" /* webpackChunkName: "component---src-pages-platforms-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-redirect-jsx": () => import("./../../../src/pages/redirect.jsx" /* webpackChunkName: "component---src-pages-redirect-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-submit-add-jsx": () => import("./../../../src/pages/submit/add.jsx" /* webpackChunkName: "component---src-pages-submit-add-jsx" */),
  "component---src-pages-submit-complete-jsx": () => import("./../../../src/pages/submit/complete.jsx" /* webpackChunkName: "component---src-pages-submit-complete-jsx" */),
  "component---src-pages-submit-index-jsx": () => import("./../../../src/pages/submit/index.jsx" /* webpackChunkName: "component---src-pages-submit-index-jsx" */),
  "component---src-pages-suggest-jsx": () => import("./../../../src/pages/suggest.jsx" /* webpackChunkName: "component---src-pages-suggest-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-topics-jsx": () => import("./../../../src/pages/topics.jsx" /* webpackChunkName: "component---src-pages-topics-jsx" */),
  "component---src-templates-blog-author-jsx": () => import("./../../../src/templates/blog/author.jsx" /* webpackChunkName: "component---src-templates-blog-author-jsx" */),
  "component---src-templates-blog-author-wordpress-jsx": () => import("./../../../src/templates/blog/authorWordpress.jsx" /* webpackChunkName: "component---src-templates-blog-author-wordpress-jsx" */),
  "component---src-templates-blog-blogpost-jsx": () => import("./../../../src/templates/blog/blogpost.jsx" /* webpackChunkName: "component---src-templates-blog-blogpost-jsx" */),
  "component---src-templates-blog-blogpost-wordpress-jsx": () => import("./../../../src/templates/blog/blogpostWordpress.jsx" /* webpackChunkName: "component---src-templates-blog-blogpost-wordpress-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-communities-jsx": () => import("./../../../src/templates/communities.jsx" /* webpackChunkName: "component---src-templates-communities-jsx" */),
  "component---src-templates-community-jsx": () => import("./../../../src/templates/community.jsx" /* webpackChunkName: "component---src-templates-community-jsx" */),
  "component---src-templates-feature-jsx": () => import("./../../../src/templates/feature.jsx" /* webpackChunkName: "component---src-templates-feature-jsx" */),
  "component---src-templates-platform-jsx": () => import("./../../../src/templates/platform.jsx" /* webpackChunkName: "component---src-templates-platform-jsx" */),
  "component---src-templates-platformtopic-jsx": () => import("./../../../src/templates/platformtopic.jsx" /* webpackChunkName: "component---src-templates-platformtopic-jsx" */),
  "component---src-templates-tool-category-jsx": () => import("./../../../src/templates/toolCategory.jsx" /* webpackChunkName: "component---src-templates-tool-category-jsx" */),
  "component---src-templates-topic-jsx": () => import("./../../../src/templates/topic.jsx" /* webpackChunkName: "component---src-templates-topic-jsx" */)
}

